/*eslint no-undef: 0*/

import { useState } from 'react';
import { UserObservable } from '../observables/user.observable';
import { LOGIN_ROUTE } from '@/constants/routes.constant';
import { AUTH_LOGIN, AUTH_REFRESH } from '@/constants/endpoints.constant';

const HEADER_X_CAREGIVING_ROLE = 'X-Caregiving-Role';
const HTTP_STATUS_UNAUTHORIZED = 401;
const HTTP_METHOD_GET = 'GET';
const HTTP_METHOD_POST = 'POST';
const HTTP_METHOD_PUT = 'PUT';
const HTTP_METHOD_PATCH = 'PATCH';
const HTTP_METHOD_DELETE = 'DELETE';

let refreshPromise = null;
const redirectToLogin = () => {
  const currentUrl = window.location.href;
  window.location = `${LOGIN_ROUTE}?redirect_to=${encodeURIComponent(currentUrl)}`;
};

const buildOptions = (options) => {
  const defaultOptions = {
    auth: true,
    role: '',
    headers: {},
    retryOnAuthError: true,
    isJSON: true,
  }

  if (!options) {
    options = {};
  }
  return { ...defaultOptions, ...options };
}


// TODO: Maybe add a mutex? I don't think it'll cause a problem since JS is single threaded

const refreshToken = async (host) => {
  // if promise is already refreshing (from another call) return that instead
  if (refreshPromise !== null) {
    return refreshPromise; // if promise is set return it, let it do what it's going to do
  }

  // otherwise, initiate a refresh, assign to refreshPromise
  refreshPromise = refreshTokenProcess(host);

  // return the promise, and reset refreshPromise to null
  return refreshPromise.then((response) => {
    refreshPromise = null;
    return response;
  });
};

const refreshTokenProcess = async (host) => {
  if (refreshPromise !== null) {
    return refreshPromise; // if promise is set return it, let it do what it's going to do
  }
  try {
    refreshPromise = makeRequest(HTTP_METHOD_POST, host, AUTH_REFRESH, { auth: false, retryOnAuthError: false });
    const response = await refreshPromise;
    refreshPromise = null; //
    const data = await response.json();

    if (response.status === 200) {
      UserObservable.updateUserCredentials(data);
      return Promise.resolve(data)
    } else if(response.status === 400) {
      UserObservable.setUser(undefined);
      redirectToLogin()
    } else {
      if(!!refreshPromise){
        redirectToLogin()
        return Promise.reject(data.error)
      } else {
        return Promise.resolve(data)
      }
    }
  } catch (e) {
    return Promise.reject(e.message)
  }
};

const fetchWithRetries = async (method, host, endpoint, options) => {
  let response = await makeRequest(method, host, endpoint, options);
  if (response.status === HTTP_STATUS_UNAUTHORIZED && options.retryOnAuthError) {
    // if auth wasn't passed to begin with, that is bad implementation, don't retry call
    if (!options.auth) {
      console.log('API requires auth, but auth was disabled. Possible implementation problem');
      return response;
    } else {
      await refreshToken(host);
      // retry call
      response = await makeRequest(method, host, endpoint, options);}
  }
  return response;
};

const makeRequest = async (method, host, endpoint, options) => {
  let { role, headers } = options;
  if (!headers) {
    headers = {};
  }

  host = host || Environment.REST_API_HOST
  // if (window.location.hostname === 'local.caregiving.com') {
  //   host = 'http://local.caregiving.com:12000';
  // }
  if (host[host.length - 1] === '/') {
    // All endpoints should come from endpoints.constant, which should all be prefixed with a / already
    host = host.substring(0, host.length - 1);
  }

  const url = `${host}${endpoint}`;

  // always add auth token if we have it. Dealing with missing or expired tokens is the job of fetchWithRetries
  const authToken = UserObservable.getAccessToken();
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  if (role) {
    headers[HEADER_X_CAREGIVING_ROLE] = role;
  }

  const requestData = {
    method,
    headers,
    credentials: 'include',
  };
  if (options.body) {
    requestData.body = options.body;
  }
  // Auth fail? If this api doesn't need auth add {auth: false} after endpoint
  const response = await fetch(url, requestData);
  return response;
};

const useHttp = (method, host) => {
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  let execute;
  const executePartial = async (endpoint, options = {}) => {
    setLoading(true);
    setStatus(0);
    setError(null);
    setData(null);

    options = buildOptions(options);

    try {
      const response = await fetchWithRetries(method, host, endpoint, options);
      const json = await response.json();

      setStatus(response.status);
      if (response.ok) {
        setLoading(false);
        setData(json);
        return Promise.resolve(json)
      } else {
        setLoading(false);
        setError(json);
        return Promise.reject(json)
      }
    } catch (e) {
      console.log('Error making request: ', e);
      setLoading(false);
      setError(e.toString());
      setStatus(-1);
      return Promise.reject(error)
    }
  };

  if ([HTTP_METHOD_POST, HTTP_METHOD_PUT, HTTP_METHOD_PATCH].includes(method)) {
    execute = async (endpoint, body, options = {}) => {
      options = buildOptions(options);

      if (body) {
        if (!options.headers) {
          options.headers = {};
        }
        if (options.isJSON) {
          options.body = JSON.stringify(body);
          options.headers['Content-Type'] = 'application/json';
        } else {
          options.body = body;
          // explicitly not setting content type, so that the browser can set it to multipart/form-data and include the boundary
        }
      }
      return await executePartial(endpoint, options);
    };
  } else {
    execute = executePartial;
  }

  return {
    status,
    loading,
    error,
    data,
    execute
  };
};

export const useHttpGet = (host) => useHttp(HTTP_METHOD_GET, host);
export const useHttpPost = (host) => useHttp(HTTP_METHOD_POST, host);
export const useHttpPut = (host) => useHttp(HTTP_METHOD_PUT, host);
export const useHttpDelete = (host) => useHttp(HTTP_METHOD_DELETE, host);
export const useHttpPatch = (host) => useHttp(HTTP_METHOD_PATCH, host);

// Dosn't really belong here, but it's a hook that uses useHttpPost, and auth is kinda tied to API requests, so.. here it is
export const useLogin = (host) => {
  const postHook = useHttpPost(host);

  const login = async (identity, password) => {
    const response = await postHook.execute(AUTH_LOGIN, { identity, password }, { auth: false, retryOnAuthError: false });

    if (response) {
      UserObservable.updateUserCredentials(response);
    }
    return response
  };

  return {
    ...postHook,
    login
  };
};
